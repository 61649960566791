.translation-headline {
  font-size: 32px;
  font-weight: 300;
  .bold {
    font-weight: 700;
  }
  &.submitted {
    color: $primary;
  }
  &.published {
    color: $success;
  }
}
