.ngx-slider {
  margin: $space-3 0 $space-6 0 !important;
}
.ngx-slider.with-legend {
  margin-bottom: $space-6 !important;
}
.ngx-slider-limit {
  visibility: hidden !important;
}

.ngx-slider-model-value {
  visibility: hidden !important;
}

.ngx-slider-ticks {
  visibility: hidden !important;
}

.ngx-slider .ngx-slider-tick-legend {
  max-width: 100px !important;
  white-space: nowrap !important;
}
.snowpack-stability-slider span.ngx-slider-ticks span.ngx-slider-tick:nth-of-type(25n + 13) {
  visibility: visible !important;
  height: 0;
}

.snowpack-stability-slider span.ngx-slider-ticks span.ngx-slider-tick:nth-of-type(25n + 1) {
  visibility: visible !important;
  width: 2px !important;
  height: 15px !important;
  top: -3px !important;
  border-radius: 0 !important;
}

.frequency-slider span.ngx-slider-ticks span.ngx-slider-tick:nth-of-type(25n + 13) {
  visibility: visible !important;
  height: 0;
}

.frequency-slider span.ngx-slider-ticks span.ngx-slider-tick:nth-of-type(25n + 1) {
  visibility: visible !important;
  width: 2px !important;
  height: 15px !important;
  top: -3px !important;
  border-radius: 0 !important;
}

.avalanche-size-slider span.ngx-slider-ticks span.ngx-slider-tick:nth-of-type(10n + 1) {
  visibility: visible !important;
  height: 0;
}

.avalanche-size-slider span.ngx-slider-ticks span.ngx-slider-tick:nth-of-type(20n + 1) {
  visibility: visible !important;
  width: 2px !important;
  height: 15px !important;
  top: -3px !important;
  border-radius: 0 !important;
}

.gliding-snow-activity-slider span.ngx-slider-ticks span.ngx-slider-tick:nth-of-type(33n + 17) {
  visibility: visible !important;
  height: 0;
}

.gliding-snow-activity-slider span.ngx-slider-ticks span.ngx-slider-tick:nth-of-type(33n + 1) {
  visibility: visible !important;
  width: 2px !important;
  height: 15px !important;
  top: -3px !important;
  border-radius: 0 !important;
}

.ngx-slider-tick-legend {
  font-size: smaller;
}

.ngx-slider-pointer {
  top: -10px !important;
  width: 24px !important;
  height: 24px !important;
}

.ngx-slider-pointer:after {
  top: 8px !important;
  left: 8px !important;
  width: 8px !important;
  height: 8px !important;
}

.ngx-slider-pointer.ngx-slider-active:after {
  background-color: $black !important;
}
