$space-0: 0px;
$space-px: 1px;
$space-1: 4px;
$space-2: 8px;
$space-3: 12px;
$space-4: 16px;
$space-6: 24px;
$space-8: 32px;
$space-16: 64px;
$space-32: 128px;
