.ph.icon-md {
  font-size: 14px;
}

.ph.icon-lg {
  font-size: 16px;
}

.ph.icon-sm {
  font-size: 12px;
}
