$albina-blue: #19abff;
$albina-blue-subtle: #bdddf1;
$albina-blue-dark: #1e83ba;
$albina-blue-pale: #eaf4fb;

$avalanche-1: #ccff66;
$avalanche-2: #ffff00;
$avalanche-3: #ff9900;
$avalanche-4: #ff0000;
$avalanche-5: #000000;

$orange: #ff9900;
